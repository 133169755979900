import React from 'react';

const LetterM = (
  <svg
    className="svg-letter-m"
    viewBox="0 0 216 120"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M207.867 31.797c-5.424-9.809-12.719-17.561-21.888-23.254C176.806 2.846 166.485 0 154.999 0c-11.48 0-21.807 2.846-30.976 8.543-6.208 3.853-11.536 8.673-16.024 14.412-4.49-5.739-9.816-10.56-16.022-14.412C82.805 2.846 72.482 0 60.998 0 49.518 0 39.19 2.846 30.021 8.543c-9.172 5.693-16.47 13.445-21.887 23.254C2.71 41.607 0 52.76 0 65.26V120h27.989V65.259c0-6.644 1.434-12.38 4.305-17.206 2.87-4.826 6.818-8.543 11.84-11.15 5.025-2.612 10.646-3.917 16.864-3.917 6.221 0 11.843 1.305 16.864 3.916 5.026 2.608 8.972 6.325 11.843 11.15 2.845 4.786 4.276 10.466 4.302 17.037 0 .057-.006.112-.006.17V120H122V65.259c0-.049-.005-.094-.005-.141.02-6.582 1.452-12.274 4.303-17.065 2.869-4.826 6.816-8.543 11.839-11.15 5.025-2.612 10.647-3.917 16.864-3.917 6.22 0 11.843 1.305 16.865 3.916 5.025 2.608 8.97 6.325 11.842 11.15 2.869 4.827 4.306 10.563 4.306 17.207V120H216V65.259c0-12.5-2.714-23.653-8.133-33.462"
      fill="#FFD314"
      fillRule="evenodd"
    />
  </svg>
);

export default LetterM;
